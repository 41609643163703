import * as Lib from './lib'

export class ProductsSearchService {
  private apiCaller

  constructor(token: string, {country_id}: Lib.T.ApiCaller.DefaultParams) {
    this.apiCaller = Lib.Helper.ApiCaller({
      isSearchService: true,
      accessToken: <string>token!,
      defaultParams: {
        country_id: country_id ? country_id : Number(process.env.NEXT_PUBLIC_DEFAULT_COUNTRY),
      },
    })
  }

  // NOTE: The below method (getProductList) should be removed because we are using the built-in fetch API. Instead, you should use getProductListWithFetchAPI.
  // NOTE: The below method (getProductList) should be removed because we are using the built-in fetch API. Instead, you should use getProductListWithFetchAPI.
  // NOTE: The below method (getProductList) should be removed because we are using the built-in fetch API. Instead, you should use getProductListWithFetchAPI.
  async getProductList(
    language: string,
    queryObj: any,
    per_page: number = 20
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.ProductSearch.getProductListResponse>> {
    if (process.env.NEXT_PUBLIC_TYPE === 'MARKETPLACE') {
      return await this.apiCaller.get(`/${language}/products`, {
        params: {
          ...queryObj,
          per_page,
        },
      })
    } else {
      return await this.apiCaller.get(`/${language}/wholesales`, {
        params: {
          ...queryObj,
          per_page,
        },
      })
    }
  }

  async getProductListWithFetchAPI(
    language: string,
    queryObj: any,
    per_page: number = 20
  ): Promise<Lib.T.FetchWrapper.FetchResponse<Lib.T.ProductSearch.getProductListResponse>> {
    if (process.env.NEXT_PUBLIC_TYPE === 'MARKETPLACE') {
      return await Lib.Helper.FetchWrapper({
        url: `/${language}/products`,
        isSearchService: true,
        options: {
          next: {
            revalidate: Number(process.env.NEXT_PUBLIC_APP_CACHE_REVALIDATE_TIME_PRODUCTS_LIST),
            tags: ['products-list-marketplace'],
          },
          searchParams: {
            ...queryObj,
            per_page,
          },
        },
      })
    } else {
      return await Lib.Helper.FetchWrapper({
        url: `/${language}/wholesales`,
        isSearchService: true,
        options: {
          next: {
            revalidate: Number(process.env.NEXT_PUBLIC_APP_CACHE_REVALIDATE_TIME_PRODUCTS_LIST),
            tags: ['products-list-wholesales'],
          },
          searchParams: {
            ...queryObj,
            per_page,
          },
        },
      })
    }
  }

  async getProductDetailsWithFetchAPI(
    language: string,
    productId: number,
    country_id: number
  ): Promise<Lib.T.FetchWrapper.FetchResponse<Lib.T.ProductSearch.ProductDetailResponse>> {
    if (process.env.NEXT_PUBLIC_TYPE === 'WHOLESALE') {
      return await Lib.Helper.FetchWrapper({
        url: `/${language}/wholesales/${productId}`,
        isSearchService: true,
        options: {
          next: {
            revalidate: Number(process.env.NEXT_PUBLIC_APP_CACHE_REVALIDATE_TIME_PRODUCT_DETAILS),
            tags: ['product-details-wholesales', `product-${productId}`],
          },
          searchParams: {
            country_id,
          },
        },
      })
    } else {
      return await Lib.Helper.FetchWrapper({
        url: `/${language}/products/${productId}`,
        isSearchService: true,
        options: {
          next: {
            revalidate: Number(process.env.NEXT_PUBLIC_APP_CACHE_REVALIDATE_TIME_PRODUCT_DETAILS),
            tags: ['product-details-marketplace', `product-${productId}`],
          },
          searchParams: {
            country_id,
          },
        },
      })
    }
  }

  // NOTE: The below method (getProductsBoughtTogether) should be removed because we are using the built-in fetch API. Instead, you should use getProductsBoughtTogetherWithFetchAPI.
  // NOTE: The below method (getProductsBoughtTogether) should be removed because we are using the built-in fetch API. Instead, you should use getProductsBoughtTogetherWithFetchAPI.
  // NOTE: The below method (getProductsBoughtTogether) should be removed because we are using the built-in fetch API. Instead, you should use getProductsBoughtTogetherWithFetchAPI.
  async getProductsBoughtTogether(
    language: string,
    productId: number
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.ProductSearch.getProductListResponse>> {
    return await this.apiCaller.get(`/${language}/products/${productId}/bought-together`)
  }

  async getProductsBoughtTogetherWithFetchAPI(
    language: string,
    productId: number
  ): Promise<Lib.T.FetchWrapper.FetchResponse<Lib.T.ProductSearch.getProductListResponse>> {
    return await Lib.Helper.FetchWrapper({
      url: `/${language}/products/${productId}/bought-together`,
      isSearchService: true,
      options: {
        next: {
          revalidate: Number(
            process.env.NEXT_PUBLIC_APP_CACHE_REVALIDATE_TIME_PRODUCTS_BOUGHT_TOGETHER
          ),
          tags: ['product-bought-together'],
        },
      },
    })
  }

  async getBrands(
    lang: string
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.ProductSearch.getBrandsResponse>> {
    return await this.apiCaller.get(`/${lang}/brands`)
  }
  async getPromotedProducts(endpoint: string): Promise<Lib.T.ApiCaller.Response<any>> {
    return await this.apiCaller.get(endpoint)
  }

  async getVariations(
    lang: string
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.ProductSearch.getVariationsResponse>> {
    return await this.apiCaller.get(`/${lang}/variations`)
  }

  async getColors(
    lang: string
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.ProductSearch.getColorsResponse>> {
    return await this.apiCaller.get(`/${lang}/colors`)
  }

  async getFabrics(
    lang: string
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.ProductSearch.getFabricsResponse>> {
    return await this.apiCaller.get(`/${lang}/fabrics`)
  }

  async getHeights(
    lang: string
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.ProductSearch.getFabricsResponse>> {
    return await this.apiCaller.get(`/${lang}/heights`)
  }

  async searchSuggestions(
    language: string,
    query: string
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.ProductSearch.SearchSuggestionItem[]>> {
    return await this.apiCaller.get(`/${language}/suggestions`, {
      params: {
        q: query,
      },
    })
  }
}
