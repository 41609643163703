import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import * as Lib from '..'
import {getCookie} from 'cookies-next'
import {defaultLocale} from '@/i18n'
import {removeClientSideCookie} from '@/utils'
import {handleRemoveAuthCookie} from '@/utils/authHelper'

export const ApiCaller = (args: Lib.T.ApiCaller.Args) => {
  const {isSearchService, accessToken, defaultParams} = args

  const axiosInstance: AxiosInstance = axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  // @ts-ignore
  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    config.baseURL = isSearchService ? Lib.Const.searchUrl! : Lib.Const.baseUrl!
    config.headers = {
      authorization: `Bearer ${accessToken}`,
      'Accept-Language': getCookie('language'),
      ...config.headers,
    }
    config.params = {
      sale_channel_id: Lib.Const.saleChannelId,
      ...defaultParams,
      ...config.params,
    }
    return config
  })

  // Add the response interceptor
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      // Handle the response
      return response
    },
    async (error: AxiosError) => {
      // Handle errors
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            if (typeof window !== 'undefined') {
              // NOTE: the below line should be remove after removing redux completely.

              handleRemoveAuthCookie()
              removeClientSideCookie('authToken')
              removeClientSideCookie('language')
              removeClientSideCookie('countryId')
              window.location.pathname = `/v2/${defaultLocale}`
            }
            break
          }
          default:
            break
        }
        return Promise.reject(error)
      }
    }
  )

  return axiosInstance
}
