import {handleGetUUID} from '@/utils/authHelper'
import * as Lib from './lib'

export class FavoritesService {
  private apiCaller

  endpoint = '/favorites'
  anonymousRoute = '/favorites/anonymous'

  constructor(token: string, {country_id}: Lib.T.ApiCaller.DefaultParams) {
    this.apiCaller = Lib.Helper.ApiCaller({
      isSearchService: false,
      accessToken: <string>token!,
      defaultParams: {
        country_id: country_id ? country_id : Number(process.env.NEXT_PUBLIC_DEFAULT_COUNTRY),
      },
    })
  }

  async addToFavoriteWithAuth(
    data: Lib.T.Favorites.AddToFavoriteData
  ): Promise<Lib.T.ApiCaller.Response<any>> {
    return await this.apiCaller.post(this.endpoint, data)
  }

  async addToFavoriteAnonymous(
    data: Lib.T.Favorites.AddToFavoriteData,
    uuid?: string
  ): Promise<Lib.T.ApiCaller.Response<any>> {
    if (uuid) {
      return await this.apiCaller.post(this.anonymousRoute, data, {
        headers: {
          UUID: uuid,
        },
      })
    }
    return await this.apiCaller.post(this.anonymousRoute, data)
  }

  // # List #
  async getFavoritesList(isLogin: boolean): Promise<Lib.T.ApiCaller.Response<any>> {
    const uuid1 = handleGetUUID()

    if (isLogin) {
      return await this.apiCaller.get(this.endpoint)
    } else {
      return await this.apiCaller.get(this.anonymousRoute, {
        headers: {
          UUID: uuid1,
        },
      })
    }
  }

  // # Delete #
  async deleteFavorite(
    isLogin: boolean,
    productId: number,
    uuid: string | null | undefined
  ): Promise<Lib.T.ApiCaller.Response<any>> {
    if (isLogin) {
      return await this.apiCaller.delete(this.endpoint, {
        params: {
          product_id: productId,
          variation_id: 1,
        },
      })
    } else {
      return await this.apiCaller.delete(this.anonymousRoute, {
        params: {
          product_id: productId,
          variation_id: 1,
        },
        headers: {
          UUID: uuid,
        },
      })
    }
  }
}
